<template>
    <div
        class="wrapper"
        :class="[
            { 'nav-open': $sidebar.showSidebar },
            { rtl: $route.meta.rtlActive },
        ]"
    >
        <notifications></notifications>
        <side-bar
            :active-color="sidebarBackground"
            :background-image="sidebarBackgroundImage"
            :data-background-color="sidebarBackgroundColor"
        >
        </side-bar>
        <div class="main-panel">
            <top-navbar></top-navbar>
            <div
                :class="{ content: !$route.meta.hideContent }"
                @click="toggleSidebar"
            >
                <zoom-center-transition :duration="200" mode="out-in">
                    <!-- your content here -->
                    <router-view></router-view>
                </zoom-center-transition>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import utils from "@/utils";

function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
    if (hasElement(className)) {
        new PerfectScrollbar(`.${className}`);
        document.getElementsByClassName(className)[0].scrollTop = 0;
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            initScrollbar(className);
        }, 100);
    }
}

function reinitScrollbar() {
    let docClasses = document.body.classList;
    let isWindows = navigator.platform.startsWith("Win");
    if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("sidebar");
        initScrollbar("sidebar-wrapper");
        initScrollbar("main-panel");

        docClasses.add("perfect-scrollbar-on");
    } else {
        docClasses.add("perfect-scrollbar-off");
    }
}

import TopNavbar from "./TopNavbar.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import FixedPlugin from "../../FixedPlugin.vue";
import { ZoomCenterTransition } from "vue2-transitions";

export default {
    components: {
        TopNavbar,
        // MobileMenu,
        ZoomCenterTransition,
    },
    mounted() {
        this.role = utils.get_role()
    },
    data() {
        return {
            sidebarBackgroundColor: "black",
            sidebarBackground: "green",
            sidebarBackgroundImage: "/img/sidebar-2.jpg",
            sidebarMini: true,
            sidebarImg: true,
            role: ""
        };
    },
    methods: {
        toggleSidebar() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        },
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize();
            }
        },
    },
    
    watch: {
        sidebarMini() {
            this.minimizeSidebar();
        },
    },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
    from {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    to {
        opacity: 1;
    }
}
.main-panel .zoomIn {
    animation-name: zoomIn95;
}
@keyframes zoomOut95 {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
}
.main-panel .zoomOut {
    animation-name: zoomOut95;
}
</style>
