<template>
    <md-toolbar
        md-elevation="0"
        class="md-transparent"
        :class="{
            'md-toolbar-absolute md-white md-fixed-top':
                $route.meta.navbarAbsolute,
        }"
    >
        <div class="md-toolbar-row">
            <div class="md-toolbar-section-start">
                <h3 class="md-title">{{ $route.name }}</h3>
            </div>
            <div class="md-toolbar-section-end">
                <md-button
                    class="md-just-icon md-round md-simple md-toolbar-toggle"
                    :class="{ toggled: $sidebar.showSidebar }"
                    @click="toggleSidebar"
                >
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </md-button>

                <div class="md-collapse">
                    <md-list>
                        <md-list-item @click="goToDashboardPage">
                            <i class="material-icons">dashboard</i>
                            <p class="hidden-lg hidden-md">Dashboard</p>
                        </md-list-item>
                        <md-list-item @click="goToUserPage">
                            <i class="material-icons">person</i>
                            <p class="hidden-lg hidden-md">Profile</p>
                        </md-list-item>

                        <md-list-item @click="logoutMethod">
                            <i class="material-icons">logout</i>
                            <p class="hidden-lg hidden-md">logout</p>
                        </md-list-item>
                    </md-list>
                </div>
            </div>
        </div>
        <DetailGuestModal
            :open="modalOpen"
            @cancel="closeModal"
            :dataMember="dataMember"
        />
    </md-toolbar>
</template>

<script>
import {Api} from "@/api/index";
import {DetailGuestModal} from "@/components";
import EventBus from "@/EventBus";
import {getToken, onMessage} from "firebase/messaging";
import {messaging} from "@/libs/firebase";

const logOut = Api.get("logOut");
const updateFirebaseToken = Api.get("updateFirebaseToken");
const checkFirebaseTokenExist = Api.get("checkFirebaseTokenExist");
const getSubscribedNotificationGroup = Api.get("getSubscribedNotificationGroup");

const vapidKey = process.env.VUE_APP_FIREBASE_VAPID_KEY;
export default {
    async mounted() {
        var self = this;
        // this.newSocket();
        EventBus.$on("showModal", (data) => {
            this.modalOpen = data["group"];
            this.dataMember = data;
        });

        EventBus.$on("cancel", (data) => {
            this.closeModal(data)
        });

        this.firebaseToken = localStorage.getItem("firebaseToken")

        if (!this.firebaseToken) {
            this.firebaseToken = await getToken(messaging, {vapidKey: vapidKey})
            localStorage.setItem("firebaseToken", this.firebaseToken)
        }
        const listGroupResponse = await getSubscribedNotificationGroup.post()
        const listGroupID = listGroupResponse.data["data"].map(group => {
            return group["id"]
        })

        if (listGroupID.length > 0 && Notification.permission === "granted") {
            const requestBody = {
                "firebaseToken": this.firebaseToken,
                "listGroup": listGroupID
            }
            updateFirebaseToken.put(requestBody)
        }


        onMessage(messaging, (payload) => {
            const jsonData = payload.data;
            self.displayNotification(jsonData)
        });
    },
    components: {
        DetailGuestModal,
    },
    data() {
        return {
            modalOpen: false,
            dataMember: [],
            selectedEmployee: "",
            firebaseToken: ""
        };
    },
    methods: {
        goToDashboardPage() {
            this.$router.push({
                name: "Trang chủ",
            });
        },
        goToUserPage() {
            this.$router.push({
                name: "Thông tin người dùng",
            });
        },
        closeModal(val) {
            this.modalOpen = val;
        },
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize();
            }
        },
        logoutMethod() {
            const requestBody = {
                "firebaseToken": this.firebaseToken,
            }
            logOut.post(requestBody).then((response) => {
                this.$router.push({name: "Login"});
            });
        },
        displayNotification(notification) {
            let customerName = notification["customerName"]
            if (customerName && customerName.length > 10) {
                customerName = `${customerName.slice(0, 10)} ...`
            }

            let title = `<p class="profile-name-noti" style="overflow: hidden">${this.$t('Customers.FullName')}: ${customerName}</p>`

            if (notification["isNewCustomer"] === "1") {
                title = `<p class="profile-name-noti">Khách mới</p>`
            }

            let customerType = notification["customerType"] || "Chưa có phân khúc"
            customerType = customerType.length > 17 ? `${customerType.slice(0, 17)} ...` : customerType


            const notificationBody = `
            <div class='main-noti-container'>
                <div class="profile-image-noti">
                    <img style="width: 48px; height: 48px;" src='${notification["image"]}'>
                </div>  
                <div class='profile-name-noti-container' >
                    ${title}
                    <p class="profile-name-noti" style="overflow: hidden">${notification["visitTime"]}</p>
                    <p class="profile-name-noti" style="overflow: hidden">${customerType}</p>
                </div>
            </div>`;

            this.$notify({
                timeout: 5000,
                message: notificationBody,
                icon: "add_alert",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "member-vip",
                dataMember: notification,
            });
        }
    },
};
</script>
